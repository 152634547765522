<template>
  <v-row no-gutters>
    <v-col cols="auto">
      <v-text-field
        v-model.trim="search"
        append-icon="search"
        :label="searchLabel"
        single-line
        hide-details
        class="remove-pad-bot-outcomes"
      >
      </v-text-field>
    </v-col>
    <v-col cols="auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="disabledRefresh" v-on="on" icon @click="emitRefresh()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </template>
        <span>
          Refresh data
        </span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import {
  $APPNEXUS,
  $BEESWAX,
  $DBM,
  $KAYZEN,
  $MEDIAMATH, $META,
  $THETRADEDESK,
  $YOUTUBE
} from '../../../../../config/dspConfig'

export default {
  name: 'OutcomesSearchBarSimple',
  data: function () {
    return {
      search: ''
    }
  },
  props: {
    disabledRefresh: {
      type: Boolean,
      default: false
    }
  },
  created () {},
  mounted () {},
  methods: {
    emitRefresh () {
      this.$emit('refresh')
    }
  },
  computed: {
    lineItemLabel () {
      let dsp = this.$route.params.dsp
      if ([$MEDIAMATH].includes(dsp)) {
        return 'strategy Id'
      } else if ([$DBM, $APPNEXUS, $BEESWAX, $YOUTUBE].includes(dsp)) {
        return 'line item id'
      } else if ([$THETRADEDESK].includes(dsp)) {
        return 'ad group id'
      } else if ([$KAYZEN].includes(dsp)) {
        return 'campaign id'
      } else if ([$META].includes(dsp)) {
        return 'adset id'
      } else {
        console.warn(`Dsp ${dsp} is not setted in 'lineItemField'`)
        return 'line item id'
      }
    },
    searchLabel () {
      return `Search ${this.lineItemLabel}`
    }
  },
  watch: {
    search: {
      immediate: false,
      handler: function (search) {
        this.$emit('search', this.search)
      }
    }
  }
}
</script>

<style scoped>

</style>
