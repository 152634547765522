<template>
  <v-dialog
    eager
    v-model="dialog"
    max-width="1500"
    transition="slide-x-transition"
    origin="center center"
    :persistent="false"
  >
    <v-card class="pa-2">

      <v-row class="ma-0">
        <v-card-title class="headline remove-pad-bot-outcomes">
          Outcomes of {{ insertionOrderDspLabel }} : {{ insertionOrderId }}
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn text icon class="ma-2" @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-row>

      <v-card-text class="remove-pad-bot-outcomes">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              Instructions : {{ instructionIds.slice(0, 5).join(', ') }}
              <span v-if="instructionIds.length > 5">
                ( {{ instructionIds.length - 5 }} more id... )
              </span>
            </span>
          </template>
          <span>
            {{ instructionIds.join(', ') }}
          </span>
        </v-tooltip>
      </v-card-text>

      <v-card-text v-if="!loadingInstruOpti && Array.isArray(instructionsOpti) && instructionsOpti.length > 0">
        <div>
          {{ getDspClientLabel }} :
          {{ instructionsOpti[0][getClientPerDsp] }}
          {{ getAdLabelPerDsp }} : {{ instructionsOpti[0][getAdFieldPerDsp] }}
        </div>
        <div>
          <a style="text-decoration: none;" target="_blank" rel="noopener noreferrer"
             :href="getUrlKeystoneSearch(instructionsOpti[0][getClientPerDsp])">
            See seat in Keystone
          </a>
        </div>
      </v-card-text>

      <v-tabs
        eager
        v-model="activeTab"
      >
        <v-tab ref="tab1">
          PRESENT
        </v-tab>
        <v-tab ref="tab2">
          PAST
        </v-tab>
        <v-tab-item eager>

          <v-card-text>
            <OutcomesSearchBarSimple
              v-on:refresh="refresh()"
              v-on:search="updateSearch"
              ref="outcomesSearchBarSimple"
            >

            </OutcomesSearchBarSimple>
          </v-card-text>

          <!-- PRESENT -->

          <v-card-text>
            <div class="circular-progress-outcomes py-12" v-show="!isAllStratLoaded">
              <div>Loading outcomes...</div>
              <v-progress-circular :size="50" indeterminate color="primary">

              </v-progress-circular>
            </div>

            <div v-for="(status, index) in statusToDisplay" :key="index">
              <v-row row no-gutters>
                <h3 class="title-outcome cursor-pointers" :class="`color-${status}`"
                    @click="open[status] = !open[status]">{{ status.toUpperCase() }}</h3>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text icon @click="open[status] = !open[status]" class="my-1">
                      <v-icon>{{ open[status] ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ open[status] ? `close ${status} outcomes` : `open ${status} outcomes` }}
                  </span>
                </v-tooltip>
              </v-row>

              <OutcomesTable
                :instructions-strat="instructionsStrat[status]"
                :loading="allLoading"
                :status="status"
                v-show="open[status]"
                :parents-instruction="parentsInstruction"
                :pagination.sync="pagination[status]"
              >

              </OutcomesTable>
            </div>

          </v-card-text>
        </v-tab-item>

        <v-tab-item eager>
          <v-card-text>
            <OutcomesSearchBarComplex
              v-on:search="updateSearchComplex"
              v-on:complexSearch="applyComplexSearch"
              :disabled-search="loadingPast"
              ref="outcomesSearchBarComplex"
            >
            </OutcomesSearchBarComplex>
          </v-card-text>

          <!-- PAST -->
          <v-card-text>
            <OutcomesTable
              :instructions-strat="instructionsStrat.past"
              :loading="loadingPast"
              :displayStatusInfo="true"
              :status="'past'"
              :parents-instruction="parentsInstructionPast"
              :pagination.sync="paginationPast"
              @update:pagination="onUpdatePagination"
            >

            </OutcomesTable>
          </v-card-text>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OutcomesTable from './OutcomesTable/OutcomesTable'
import { stratMixin } from '../../../mixins/stratMixin'
import { InstruStratProcessor } from '../../../../utils/Processor/InstruStratProcessor'
import OutcomesSearchBarSimple from './OutcomesSearchBar/OutcomesSearchBarSimple'
import OutcomesSearchBarComplex from './OutcomesSearchBar/OutcomesSearchBarComplex'
import { generalMixin } from '@/mixins/generalMixin'
import { keystonePathGenerator } from '@/mixins/keystonePathGenerator'
import { callInstructionsOptiMixin } from '@/mixins/callInstructionsOptiMixin'
import _ from 'lodash'

/**
 * @type {DataOptions}
 */
const basePagination = {
  sortBy: ['push_date'],
  sortDesc: [true],
  multiSort: false,
  page: 1,
  itemsPerPage: -1
}

/**
 * id for test in staging :
 * APPX : 939064, 679272
 MM : 695851
 DBM : 10040260, 2647206
 TTD : 7l2wpa8
 YOUTUBE : 14529742
 BEESWAX : nativetouch_102
 */

export default {
  name: 'OutcomesDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    instructionIdProps: {
      type: [Number, String],
      default: null
    },
    insertionOrderId: {
      type: [Number, String],
      default: null
    }
  },
  data: () => {
    return {
      dialog: false,
      /**
       * @type {{[key: string]: InstructionStrat[]}}
       */
      instructionsStrat: {
        live: [],
        waiting: [],
        to_push: [],
        error: [],
        past: []
      },
      loadingPast: false,
      loadedPast: false,
      open: {
        live: true,
        waiting: true,
        to_push: true,
        error: true
      },
      allLoading: false,
      isAllStratLoaded: false,
      search: '',
      searchComplex: '',
      /**
       * number of day before today limit date of the instruction strats in error
       */
      errorRecency: 10,
      instruStratProcessor: new InstruStratProcessor(),
      activeTab: 0,
      /**
       * used for fixing the tab (display) error (see fixTabBug)
       */
      tabFixed: false,
      parentsInstruction: [],
      parentsInstructionPast: [],
      LIMIT_INSTRU_STRATS: 1000,
      /**
       * @type {StratStatus[]}
       */
      allStatus: ['error', 'to_push', 'waiting', 'live'],
      paginationPast: {
        sortBy: ['push_date'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 50
      },
      pagination: {
        error: _.cloneDeep(basePagination),
        to_push: _.cloneDeep(basePagination),
        waiting: _.cloneDeep(basePagination),
        live: _.cloneDeep(basePagination)
      },
      /**
       * @type {OutcomesComplexSearch}
       */
      currentComplexSearch: {
        startDate: null,
        endDate: null,
        status: [],
        executionMode: null
      }
    }
  },
  mixins: [
    stratMixin, generalMixin, keystonePathGenerator, callInstructionsOptiMixin
  ],
  components: {
    OutcomesTable,
    OutcomesSearchBarSimple,
    OutcomesSearchBarComplex
  },
  created: () => {
  },
  mounted: function () {
    this.dialog = this.value
  },
  methods: {
    getParentsId () {
      let parentIds = new Set()
      const pcidLive = this.instructionsStrat.live.filter(item => item.p_cid).map(item => item.p_cid)
      pcidLive.forEach(item => parentIds.add(item))

      const pcidPush = this.instructionsStrat.to_push.filter(item => item.p_cid).map(item => item.p_cid)
      pcidPush.forEach(item => parentIds.add(item))

      const pcidWaiting = this.instructionsStrat.waiting.filter(item => item.p_cid).map(item => item.p_cid)
      pcidWaiting.forEach(item => parentIds.add(item))

      const pcidError = this.instructionsStrat.error.filter(item => item.p_cid).map(item => item.p_cid)
      pcidError.forEach(item => parentIds.add(item))
      return [...parentIds]
    },
    getParentsIdsPast () {
      return this.instructionsStrat.past.filter(item => item.p_cid).map(item => item.p_cid)
    },
    async callParentsInstruction (parentIds) {
      const numbByBulk = 50

      const dsp = this.$route.params.dsp

      if (!parentIds.length) {
        return
      }
      let parents = []

      // send by bulk for avoid error 400 too long request
      for (let i = 0; i < parentIds.length; i += numbByBulk) {
        let start = i
        let end = i + numbByBulk

        const response = await this.$apiCaller.getInstructionsStratBucketIds(parentIds.slice(start, end), dsp, false)

        if (this.$apiCaller.isResponseError(response)) {
          this.$store.commit('setErrorMessage', `Error when calling outcomes. (Get parents)`)
        } else {
          parents = [...response.data, ...parents]
        }
      }
      return parents
    },
    async callApiInstructionStrat (insertionOrderId) {
      if (!insertionOrderId) {
        console.warn('[OutcomesDialog] Insertion Order id Is Null, do not call api')
        return
      }
      this.resetInstructionsStrat()

      await this.callAllStatus(insertionOrderId, this.defaultRecency)

      // we are sur than all is loaded now, we can launch the remove process.
      this.removeStratErrorProcess()
      this.$nextTick(async () => {
        this.parentsInstruction = await this.callParentsInstruction(this.getParentsId())
      })
    },
    async callAllStatus (insertionOrderId, recency = null) {
      this.isAllStratLoaded = false
      this.allLoading = true
      const lineItemId = this.search ? this.search : null
      const dsp = this.$route.params.dsp
      let response = await this.$apiCaller.getInstructionsStratWithInsertionOrderid(
        insertionOrderId, this.allStatus, dsp, recency, null,
        this.LIMIT_INSTRU_STRATS * this.allStatus.length, 0,
        null, null, null, null, lineItemId
      )

      this.resetAllStatusInstructionStrat()

      if (this.checkResponse(response)) {
        response.data.forEach(item => {
          this.instructionsStrat[item.status].push(item)
        })
      }
      this.allLoading = false
      this.isAllStratLoaded = true
    },
    /**
     * @param searchConfig {OutcomesComplexSearch}
     * if status is not empty, call api search for status for each day. Otherwise, search for execution_mode.
     */
    async applyComplexSearch (searchConfig) {
      if (!searchConfig.startDate || !searchConfig.endDate) {
        console.warn('No end date or start date.')
        return
      }
      this.instructionsStrat.past = []
      this.currentComplexSearch = searchConfig
      let instructionId = this.instructionIdProps ? this.instructionIdProps : null
      this.loadingPast = true

      if (!this.insertionOrderId) {
        console.warn('[Instru strat] No call to the api cause no io_id.')
        return
      }
      let lineItemId = this.searchComplex ? this.searchComplex : null

      if (searchConfig.status && searchConfig.status.length) {
        let response = await this.callApiComplexeSearch(
          null,
          instructionId,
          searchConfig.status,
          null,
          searchConfig.startDate,
          searchConfig.endDate,
          lineItemId
        )

        if (this.checkResponse(response)) {
          this.$set(this.instructionsStrat, 'past', response.data)
        }
      } else {
        let response = await this.callApiComplexeSearch(
          null,
          instructionId,
          null,
          searchConfig.executionMode,
          searchConfig.startDate,
          searchConfig.endDate,
          lineItemId
        )

        if (this.checkResponse(response)) {
          const d = response.data.filter(item => item.push_date !== null)
          this.$set(this.instructionsStrat, 'past', d)
        }
      }
      this.parentsInstructionPast = await this.callParentsInstruction(this.getParentsIdsPast())
      this.loadingPast = false
    },
    async callApiComplexeSearch (insertionDay, instructionId, status = null, execMode = null, insertionDayGte = null, insertionDayLte = null, lineItemId = null) {
      if (!this.insertionOrderId) {
        console.warn('[Instru strat] No call to the api cause no io_id.')
        return
      }
      return this.$apiCaller.getInstructionsStratWithInsertionOrderid(
        this.insertionOrderId,
        status,
        this.$route.params.dsp,
        null,
        insertionDay,
        this.paginationPast.itemsPerPage,
        (this.paginationPast.page - 1) * this.paginationPast.itemsPerPage,
        instructionId,
        execMode,
        insertionDayGte,
        insertionDayLte,
        lineItemId
      )
    },
    close () {
      this.resetValues()
      this.resetInstructionsStrat()
      this.dialog = false
    },
    removeStratErrorProcess () {
      this.removeOldStratError()
      this.removeDuplicateStratError()
    },
    removeOldStratError () {
      const unicityKey = this.lineItemField
      let idList = this.instruStratProcessor.getOldStratError(
        this.instructionsStrat.error,
        this.instructionsStrat.live,
        unicityKey
      )

      const filtered = this.instructionsStrat.error.filter((item) => {
        return idList.indexOf(item.id) === -1
      })

      this.$set(this.instructionsStrat, 'error', filtered)
    },
    removeDuplicateStratError () {
      let idList = this.instruStratProcessor.getNotDuplicateErrorList(this.instructionsStrat.error, this.unicityKey)

      const filtered = this.instructionsStrat.error.filter((item) => {
        return idList.indexOf(item.id) !== -1
      })
      this.$set(this.instructionsStrat, 'error', filtered)
    },
    refresh () {
      this.callApiInstructionStrat(this.insertionOrderId)
    },
    resetValues () {
      this.loadingPast = false
      this.search = ''
      this.searchComplex = ''

      if (this.$refs['outcomesSearchBarComplex'] !== undefined) {
        this.$refs['outcomesSearchBarComplex'].resetSearch()
      }

      if (this.$refs['outcomesSearchBarSimple'] !== undefined) {
        this.$refs['outcomesSearchBarSimple'].search = ''
      }

      this.open = {
        live: true,
        to_push: true,
        waiting: true,
        error: true
      }
      this.loadedPast = false
      this.activeTab = 0
    },
    resetAllStatusInstructionStrat () {
      this.instructionsStrat.error = []
      this.instructionsStrat.to_push = []
      this.instructionsStrat.waiting = []
      this.instructionsStrat.live = []
    },
    resetInstructionsStrat () {
      this.loadedPast = false
      this.resetAllStatusInstructionStrat()
      this.instructionsStrat.past = []
    },
    checkResponse (response) {
      if (this.$apiCaller.isResponseError(response, true)) {
        if (this.$apiCaller.isApiResponseStatusUnauthorized(response)) {
          console.warn('Unauthorized')
          this.close()
          this.$store.commit('setAskRelog', true)
          return false
        }
        console.warn('SERVER ERROR')
        this.close()
        this.$store.commit('setErrorMessageWithResponse', response)
        return false
      }
      return true
    },
    updateSearchComplex (toSearch) {
      this.searchComplex = toSearch
      this.applyComplexSearch(this.currentComplexSearch)
    },
    updateSearch (toSearch) {
      this.search = toSearch
      this.callApiInstructionStrat(this.insertionOrderId)
    },
    fixTabBug () {
      /*
      (same as IOForm tab bug)
       litle hack for obligate the v-slider to beeing displayed
       In fact, without that, when the dialog is open for the first time,
       the v-slider not working
       A click on tabtwo and tabone is simulated for avoid this issue
       when form is on edit, dialog is open on tab 2
       For avoid to make this operation more than one time, a value 'tabFixed' is plugged
      */
      if (this.tabFixed) {
        return
      }
      try {
        this.$refs['tab2'].$el.children[0].click()
        this.$refs['tab1'].$el.children[0].click()
        this.tabFixed = true
      } catch (TypeError) {

      }
    },
    onUpdatePagination () {
      this.applyComplexSearch(this.currentComplexSearch)
    }
  },
  computed: {
    statusToDisplay () {
      return this.allStatus.filter((status) => {
        return (status === 'live' || this.instructionsStrat[status].length > 0) && this.isAllStratLoaded
      })
    },
    insertionOrderDspLabel () {
      let dsp = this.$route.params.dsp
      if ([this.$MEDIAMATH, this.$THETRADEDESK, this.$BEESWAX, this.$META].indexOf(dsp) !== -1) {
        return 'Campaign Id'
      } else if ([this.$DBM, this.$APPNEXUS].indexOf(dsp) !== -1) {
        return 'Insertion Order ID'
      } else {
        console.warn(`Dsp ${dsp} is not setted in 'insertionOrderDspLabel'`)
        return 'Insertion Order ID'
      }
    },
    instructionIds () {
      let instructionIds = new Set()
      for (let key in this.instructionsStrat) {
        const instruIds = this.instructionsStrat[key].map(item => item.instruction_id)
        instruIds.forEach(item => instructionIds.add(item))
      }
      return [...instructionIds]
    },
    defaultRecency () {
      return ['development', 'staging'].indexOf(this.$_VUE_ENV) !== -1 ? 1000 : 14 // special case staging / dev for allowing easy debugging / testing with non updated data
    }
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    dialog (val) {
      if (val) {
        const path = this.$router.resolve({
          params: {
            dsp: this.dsp,
            openDialog: 'outcomes',
            dialogId: this.insertionOrderId
          },
          query: this.$route.query
        })
        if (path.href !== this.$route.fullPath) {
          this.$router.replace(path.location)
        }
      } else {
        const formStatus = this.$store.getters.getFormStatus
        // default url params
        let params = {
          dsp: this.dsp,
          openDialog: null,
          dialogId: null
        }

        // if ioForm was open, set the url form for ioForm
        if (formStatus === 'EDIT') {
          params = {
            dsp: this.dsp,
            openDialog: 'ioForm',
            dialogId: this.insertionOrderId
          }
        }
        // replace url
        const path = this.$router.resolve({
          params: params,
          query: this.$route.query
        })

        if (path.href !== this.$route.fullPath) {
          this.$router.replace(path.location)
        }
      }
      this.$plausibleHelper.trackPageview()
      this.$emit('input', val)
    },
    insertionOrderId: {
      deep: true,
      immediate: true,
      handler (val) {
        if (this.insertionOrderId) {
          this.fixTabBug()
          this.callApiInstructionStrat(this.insertionOrderId)
          this.callInstructionOpti(this.insertionOrderId, false)
        }
      }
    },
    instructionIdProps: {
      deep: true,
      immediate: true,
      handler (val) {
        if (this.insertionOrderId && this.instructionIdProps) {
          this.fixTabBug()
          this.callApiInstructionStrat(this.insertionOrderId)
          this.callInstructionOpti(this.insertionOrderId, false)
        }
      }
    },
    activeTab: {
      immediate: true,
      handler (val) {
        if (val === 1 && typeof this.$refs['outcomesSearchBarComplex'] !== 'undefined' &&
          !this.instructionsStrat.past.length && !this.loadingPast) {
          this.$refs['outcomesSearchBarComplex'].launchSearch()
        }
      }
    }
  }
}
</script>

<style scoped>
.color-live {
  color: #3dc73d;
}

.color-to_push {
  color: orange;
}

.color-error {
  color: red;
}

.color-waiting {
  color: #b17903;
}

.title-outcome {
  margin-top: 0.7em;
}

.v-card__text {
  padding: 16px;
  width: 100%;
  padding-bottom: 0;
  padding-top: 0;
}

.circular-progress-outcomes {
  text-align: center;
}
</style>
