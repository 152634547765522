
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'
import InfoTextIcon from '@/components/Common/InfoTextIcon.vue'
import { InstructionStrat } from '../../../../../../types/instruction_type'

type ConfigItemInfoDate= {
  label: string
  value: keyof InstructionStrat
  tooltipText: string
  isDate?: boolean
}
@Component({
  components: {
    CopyClipboardComponent,
    InfoTextIcon
  }
})
export default class InfoDateId extends Vue {
  @Prop({ required: true }) item!: InstructionStrat

  configItems: ConfigItemInfoDate[] = [
    {
      label: 'Instruction id',
      value: 'instruction_id',
      tooltipText: 'Copy instruction id'
    },
    {
      label: 'Strat id',
      value: 'id',
      tooltipText: 'Copy strat id'
    },
    {
      label: 'Computation date',
      value: 'computation_date',
      tooltipText: 'Copy computation date',
      isDate: true
    },
    {
      label: 'Insertion date',
      value: 'insertion_date',
      tooltipText: 'Copy insertion date',
      isDate: true
    }
  ]

  displayValue (config: ConfigItemInfoDate, item: InstructionStrat) {
    if (config.isDate) {
      return item[config.value] ? this.$commonUtils.formatDate(item[config.value] as string) : 'NC'
    }
    return item[config.value] ? item[config.value] : 'NC'
  }
}
