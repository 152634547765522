
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import NewLabelValue from '@/components/TableComponents/Outcomes/OutcomesTable/FragmentsTable/NewLabelValue.vue'

@Component({
  components: {
    NewLabelValue
  }
})
export default class NewLabelValueWithTooltip extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ required: true }) value!: string | number
  @Prop({ default: 'NC' }) defaultValue!: string
  @Prop({ default: false }) isDate!: boolean
  @Prop({ default: 'black' }) colorValue!: string
  @Prop({ default: 'black' }) colorLabel!: string
  @Prop({ default: false }) boldValue!: boolean
  @Prop({ default: true }) boldLabel!: boolean
  @Prop({ default: '7em' }) widthLabel!: string
  @Prop({ default: '' }) tooltipText!: string
}
