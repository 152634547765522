
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import OutcomesInfoTooltip
  from '@/components/TableComponents/Outcomes/OutcomesTable/FragmentsTable/OutcomesInfoTooltip.vue'

@Component({
  components: {
    OutcomesInfoTooltip
  }
})
export default class ABParamsTooltip extends Vue {
  @Prop({ required: true }) A!: number
  @Prop({ required: true }) B!: number
}
