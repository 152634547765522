<template>
  <v-data-table
    v-if="$store.getters.isInstructionStratEnabled"
    :items="instructionsStratSorted"
    class="elevation-1 data-table-outcomes"
    :loading="loading"
    :headers="headersByDsp"
    :class="`status_${status} outcomes-data-table`"
    :options="pagination"
    :server-items-length="totalItems"
    @update:options="onUpdatePagination"
    :footer-props="{
      itemsPerPageOptions: [{text: 'All','value':-1},5,10,25,50,100,200]
    }"
  >
    <template v-slot:headerCell="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" :class="`header_strat_${props.header.text.replace(' ', '_')}`">
              {{ props.header.text }}
            </span>
          </template>
          <span>
            {{props.header.tooltipText ? props.header.tooltipText : props.header.text }}
          </span>
        </v-tooltip>
    </template>

    <template v-slot:item="props">
      <tr>
        <!-- Dates & ID -->
        <td class="td-dates-and-id">
          <NewLabelValue
            :label="lineItemLabel"
            :value="props.item[lineItemField]"
          >
          </NewLabelValue>
          <NewLabelValue
            label="Computation"
            :value="props.item.computation_date"
            :is-date="true"
          >
          </NewLabelValue>
          <NewLabelValue
            label="Push"
            :value="props.item.push_date"
            :is-date="true"
            :color-label="isRedPushDate(props.item.push_date) ? 'red' : 'black'"
            :color-value="isRedPushDate(props.item.push_date) ? 'red' : 'black'"
          >
          </NewLabelValue>
          <v-row no-gutters wrap row>
            <v-col class="info-text-id">
              <InfoDateId
                :item="props.item"
              >
              </InfoDateId>
            </v-col>
          </v-row>
        </td>
        <!-- Execution info -->
        <td class="td-execution-info" v-if="isDisplayedHeader('execution_info')">
          <NewLabelValue
            label="Exec mode"
            :value="$capitalize(props.item.execution_mode)"
            :color-value="getExecModeColor(props.item.execution_mode)"
            :bold-value="true"
          >
          </NewLabelValue>
          <NewLabelValue
            label="Otto"
            :value="booleanToString(props.item.opti_auto)"
          >
          </NewLabelValue>
          <NewLabelValue
            label="Algo"
            :value="getAlgoValue(props.item.algorithm)"
          >
          </NewLabelValue>
          <NewLabelValue
            v-if="getUseMatchingData(props.item)"
            label="Matching IO"
            value="Activated"
          >
          </NewLabelValue>
        </td>
        <!-- Otto Parameters -->
        <td class="td-otto-parameters" v-if="isDisplayedHeader('otto_parameters')">
          <v-row no-gutters wrap>
            <v-col class="shrink" v-if="displayInfoABParams(props.item)">
              <ABParamsTooltip
                :A="props.item.objective?.KPI_CPA"
                :B="getTooltipOffset(props.item)"
              >
              </ABParamsTooltip>
            </v-col>
            <v-col grow>
              <NewLabelValue
                label="KPI_CPA"
                :value="getKpiCPA(props.item)"
                :width-label="displayInfoABParams(props.item) ? removeWidthTooltip(7) : '7em'"
              >
              </NewLabelValue>
            </v-col>
          </v-row>

          <NewLabelValue
            v-if="!isYoutube"
            label="Offset"
            :value="roundNumOrNc(getOffset(props.item), 2)"
          >
          </NewLabelValue>
          <NewLabelValue
            v-if="!isYoutube"
            label="B"
            :value="numberToNegative(getABParamsProperty(props.item, 'B'))"
          >
          </NewLabelValue>
          <NewLabelValue
            label="Datacost"
            :value="numberToNegative(roundNumOrNc(props.item.data_cost, 3))"
          >
          </NewLabelValue>
        </td>
        <!-- Viz & max bid applied -->
        <td class="td-viz-and-max-bid-applied"
            v-if="isDisplayedHeader('viz_and_max_bid_applied')"
        >
          <NewLabelValue
            label="Max Bid"
            :value="$commonUtils.isNotNullOrUndefined(props.item.max_CPM) ? roundNumOrNc(props.item.max_CPM, 3) : 'NC'"
            :color-label="isAvgBidTooHigh(props.item) ? 'red' : 'black'"
            :color-value="isAvgBidTooHigh(props.item) ? 'red' : 'black'"
            :bold-value="isAvgBidTooHigh(props.item)"
          >
          </NewLabelValue>
          <NewLabelValue
            v-if="$commonUtils.isNotNullOrUndefined(props.item.objective) && $commonUtils.isNotNullOrUndefined(props.item.objective.min_viz)"
            :label="labelMinViz"
            :value="$commonUtils.isNotNullOrUndefined(props.item.objective) ? ratioValueTreatment(props.item.objective.min_viz) : 'NC'"
          >
          </NewLabelValue>
          <NewLabelValue
            v-if="$commonUtils.isNotNullOrUndefined(props.item.objective) && $commonUtils.isNotNullOrUndefined(props.item.objective.min_video_viz)"
            label="Min Video viz"
            :value="$commonUtils.isNotNullOrUndefined(props.item.objective) ? ratioValueTreatment(props.item.objective.min_video_viz) : 'NC'"
          >
          </NewLabelValue>
        </td>
        <!-- Bid & ratios -->
        <td class="td-bid-and-ratios" v-if="isDisplayedHeader('bid_and_ratios')">
          <NewLabelValue
            label="Av. Bid"
            :value="roundNumOrNc(props.item.average_bid)"
            :color-value="isAvgBidError(props.item) ? 'red' : 'black'"
            :color-label="isAvgBidError(props.item) ? 'red' : 'black'"
            :bold-value="isAvgBidError(props.item)"
            :widthLabel="widthLabelBidAndRatio"
          >
          </NewLabelValue>
          <NewLabelValueWithTooltip
            label="% imps with a positive bid"
            :value="roundNumOrNc(props.item.percentage_positive_imps, 3, '%')"
            :color-value="perPositiveTooLow(props.item) ? 'red' : 'black'"
            :color-label="perPositiveTooLow(props.item) ? 'red' : 'black'"
            :bold-value="perPositiveTooLow(props.item)"
            :widthLabel="widthLabelBidAndRatio"
            :tooltip-text="tooltipAverageBid"
          >
          </NewLabelValueWithTooltip>
          <template v-if="!isYoutube">
            <NewLabelValue
              v-if="isDisplayedOptiRatio(props.item)"
              label="Ratio"
              :value="$commonUtils.isNotNullOrUndefined(props.item.opti_ratio) ? displayBigFloat(props.item.opti_ratio) : 'NC'"
              :widthLabel="widthLabelBidAndRatio"
            >
            </NewLabelValue>
            <v-row no-gutters wrap>
              <v-col class="shrink">
                <RatioTooltip
                  v-if="displayRatioTooltip(props.item)"
                  :model-info="props.item.p3_model_info"
                >
                </RatioTooltip>
              </v-col>
              <v-col grow>
                <NewLabelValue
                  v-if="displaySource(props.item)"
                  label="Source"
                  :value="getSource(props.item)"
                  :width-label="displayRatioTooltip(props.item) ? removeWidthTooltip(widthLabelBidAndRatio) : widthLabelBidAndRatio"
                >
                </NewLabelValue>
              </v-col>
            </v-row>
          </template>
        </td>
        <!-- Funnel info -->
        <td v-if="isDisplayedHeader('funnel_info')">
          <NewLabelValue
            label="PV window"
            :value="props.item.true_PV_window"
          >
          </NewLabelValue>

          <NewLabelValue
            label="Frequency"
            :value="frequencyCapToString(props.item.frequency_cap)"
          >
          </NewLabelValue>

          <div v-if="props.item.data_pixel_id && Array.isArray(props.item.data_pixel_id)">
            <ConversionFunnelComponent
              label="KPI"
              :conversion-funnel="pixelIdToConversionFunnel(props.item.data_pixel_id)"
              :nb-displayed-pixels="1"
              :nb-displayed-funnels="1"
              no-index
            >
              <template v-slot:label>
                <NewLabelValue
                  label="KPI"
                  :value="valueKpi(props.item)"
                >
                </NewLabelValue>
              </template>
            </ConversionFunnelComponent>

            <v-menu

            >
              <template v-slot:activator="{ on }">
                <div  v-on="on" class="mini-info-text">Click to show more</div>
              </template>
              <div class="pixel-v-menu">
                <ConversionFunnelComponent
                  label="KPI"
                  no-index
                  :conversion-funnel="pixelIdToConversionFunnel(props.item.data_pixel_id)"
                >
                  <template v-slot:label>
                    <NewLabelValue
                      label="KPI"
                      :value="valueKpi(props.item)"
                    >
                    </NewLabelValue>
                  </template>
                </ConversionFunnelComponent>
              </div>
            </v-menu>
          </div>
          <NewLabelValue
            v-else
            label="KPI"
            :value="!!props.item.data_pixel_id ? props.item.data_pixel_id : 'NC'"
          >
          </NewLabelValue>

        </td>
        <!-- Additional Info headers -->
        <td v-if="isDisplayedHeader('additional_info')">
          <NewLabelValue
            label="Last date report"
            :value="dateFormat(props.item.last_date_report)"
            longLabel
          >
          </NewLabelValue>
          <NewLabelValue
            label="Spent real"
            :value="!!props.item.spent_real ? props.item.spent_real : 'NC'"
            longLabel
          >
          </NewLabelValue>
          <NewLabelValue
            label="adv_datetime"
            :value="dateFormat(props.item.adv_datetime)"
            longLabel
          >
          </NewLabelValue>
          <NewLabelValue
            label="tz_offset"
            :value="!!props.item.tz_offset ? props.item.tz_offset : 'NC'"
            longLabel
          >
          </NewLabelValue>
        </td>

        <td v-if="isDisplayedHeader('error')">
          <v-row no-gutters wrap>
            <v-col cols="9">
              <v-menu  auto :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <div class="error-details">
                    {{$isNotNullOrUndefined(props.item.details) ? props.item.details.split(" ", 6).join(" ") + '...' : 'NC'}}
                    <span v-on="on" class="mini-info-text">Click to show more</span>
                  </div>
                </template>
                <div class="v-menu-errors">{{$isNotNullOrUndefined(props.item.details) ? props.item.details : 'NC'}}</div>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <CopyClipboardComponent
                :to-copy="props.item.details"
                tooltip-text="Copy details to clipboard"
              >
              </CopyClipboardComponent>
            </v-col>
          </v-row>
        </td>

        <td style="text-align: center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                    color="gray"
                    outlined
                    target="_blank"
                    rel="noopener noreferrer"
                    :to="generateUrl(props.item.id)"
                    :disabled="!modelsActivated || $isNullOrUndefined(props.item.model)"
                    class="strat-model-button elevation-3"
                    icon
                >
                  <v-icon color="gray">remove_red_eye</v-icon>
                </v-btn>
              </div>
            </template>
            <span>
              {{modelsActivated
                  ? $isNullOrUndefined(props.item.model)
                        ? 'No model available for this instruction strat'
                        : 'Go to model'
                  : 'Available soon...'
              }}
            </span>
          </v-tooltip>
        </td>

        <td v-if="status === 'past'">
          <NewLabelValue
            label="Status"
            :value="props.item.status"
            longLabel
            :color-value="getStatusColor(props.item.status)"
          >
          </NewLabelValue>
        </td>
      </tr>
    </template>
    <template v-slot:pageText="props">
      {{ props.pageStart }} - {{ displayedPageStop }} of {{ props.itemsLength }}
      {{ displayedPageStop === props.itemsLength ? '' : '+' }}
    </template>

  </v-data-table>
</template>

<script>
import { stratMixin } from '../../../../mixins/stratMixin'
import ConversionFunnelComponent from '../../../Common/ConversionFunnelComponent'
import { $DBM } from '../../../../../config/dspConfig'
import _ from 'lodash'
import NewLabelValue from '@/components/TableComponents/Outcomes/OutcomesTable/FragmentsTable/NewLabelValue'
import NewLabelValueWithTooltip
  from '@/components/TableComponents/Outcomes/OutcomesTable/FragmentsTable/NewLabelValueWithTooltip'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent'
import InfoDateId from '@/components/TableComponents/Outcomes/OutcomesTable/FragmentsTable/InfoDateId'
import ABParamsTooltip from '@/components/TableComponents/Outcomes/OutcomesTable/FragmentsTable/ABParamsTooltip'
import RatioTooltip from '@/components/TableComponents/Outcomes/OutcomesTable/FragmentsTable/RatioTooltip'
import { loadOptions } from '@babel/core'

/**
 * up 30/04/2021 => https://trello.com/c/GDiV6pcm/922-surcouche-outcomes-quelques-ajouts-sur-longlet
 * up 04/05/2023 => https://scibids-k.atlassian.net/wiki/spaces/PAIN/pages/1536229379/Outcome+-+Modifications
 */
export default {
  name: 'OutcomesTable',
  mixins: [stratMixin],
  props: {
    instructionsStrat: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      required: true
    },
    displayStatusInfo: {
      type: Boolean,
      required: false,
      default: false
    },
    parentsInstruction: {
      type: Array,
      default () {
        return []
      }
    },
    pagination: {
      type: Object,
      default () {
        return {
          sortBy: ['push_date'],
          groupDesc: true,
          sortDesc: [true],
          page: 1,
          itemsPerPage: -1
        }
      }
    }
  },
  components: {
    NewLabelValue,
    ConversionFunnelComponent,
    NewLabelValueWithTooltip,
    CopyClipboardComponent,
    InfoDateId,
    ABParamsTooltip,
    RatioTooltip
  },
  data: function () {
    return {
      showMore: [],
      tooltipAverageBid: 'This value represents the average positive bid of the model simulated for last 20 days of data (the negative / null values are not taken into account)',
      // TODO : improve header system
      idHeaders: {
        [this.$APPNEXUS]: {
          text: 'Dates & ID',
          align: 'left',
          sortable: true,
          value: 'push_date',
          class: 'header-instru-strat'
        },
        [this.$MEDIAMATH]: {
          text: 'Dates & ID',
          align: 'left',
          sortable: true,
          value: 'push_date',
          class: 'header-instru-strat'
        },
        [this.$DBM]: {
          text: 'Dates & ID',
          align: 'left',
          sortable: true,
          value: 'push_date',
          class: 'header-instru-strat'
        },
        [this.$THETRADEDESK]: {
          text: 'Dates & ID',
          align: 'left',
          sortable: true,
          value: 'push_date',
          class: 'header-instru-strat'
        },
        [this.$BEESWAX]: {
          text: 'Dates & ID',
          align: 'left',
          sortable: true,
          value: 'push_date',
          class: 'header-instru-strat'
        },
        [this.$YOUTUBE]: {
          text: 'Dates & ID',
          align: 'left',
          sortable: true,
          value: 'push_date',
          class: 'header-instru-strat'
        },
        [this.$FACEBOOK]: {
          text: 'Dates & ID',
          align: 'left',
          sortable: true,
          value: 'push_date',
          class: 'header-instru-strat'
        },
        [this.$KAYZEN]: {
          text: 'Dates & ID',
          align: 'left',
          sortable: true,
          value: 'push_date',
          class: 'header-instru-strat'
        },
        [this.$META]: {
          text: 'Dates & ID',
          align: 'left',
          sortable: true,
          value: 'push_date',
          class: 'header-instru-strat'
        }
      },
      modelHeader: {
        text: 'Model',
        align: 'left',
        sortable: false,
        value: 'instruction_id',
        class: 'header-instru-strat'
      },
      headers: [
        {
          text: 'Execution Info',
          align: 'left',
          sortable: false,
          value: 'campaign_id',
          class: 'header-instru-strat',
          name: 'execution_info'
        },
        {
          text: 'Otto Parameters',
          align: 'left',
          sortable: false,
          value: 'ad_group_id',
          tooltipText: `Info regarding pixel and attribution window taken into account by the algo`,
          class: 'header-instru-strat',
          name: 'otto_parameters'
        },
        {
          text: 'Viz & max bid applied',
          align: 'left',
          sortable: false,
          value: 'average_bid',
          class: 'header-instru-strat',
          name: 'viz_and_max_bid_applied'
        },
        {
          text: 'Bid & ratios',
          align: 'left',
          sortable: false,
          value: 'opti_ratio',
          tooltipText: `opti ratio`,
          class: 'header-instru-strat',
          name: 'bid_and_ratios'
        },
        {
          text: 'Additional Info ',
          align: 'left',
          sortable: false,
          value: 'last_date_report',
          class: 'header-instru-strat',
          name: 'additional_info'
        },
        {
          text: 'Funnel Info',
          align: 'left',
          sortable: false,
          value: 'strategy_id',
          tooltipText: `Info regarding constraints that have been applied to the model`,
          class: 'header-instru-strat',
          name: 'funnel_info'
        },
        {
          text: 'Error',
          align: 'left',
          sortable: false,
          value: 'instruction_id',
          tooltipText: `Error`,
          class: 'header-instru-strat',
          name: 'error'
        }
      ],
      statusInfoHeader: {
        text: 'Status',
        align: 'left',
        sortable: false,
        value: 'status',
        class: 'header-instru-strat'
      },
      widthLabelBidAndRatio: '12.5em',
      tooltipTipInfoSize: 300
    }
  },
  methods: {
    getAlgoValue (algoValue) {
      const dsp = this.$route.params.dsp
      if (dsp === this.$MEDIAMATH) {
        return 'reglog'
      } else if (dsp === this.$BEESWAX) {
        return 'bid multipliers'
      } else if (dsp === this.$APPNEXUS) {
        return algoValue === 'genie' ? 'Genie reglog' : 'Bonsai models'
      } else if (dsp === this.$DBM) {
        return algoValue === 'custom_algo' ? algoValue : 'bid multipliers'
      } else {
        return 'NC'
      }
    },
    getUseMatchingData (item) {
      return !!item?.model_info?.use_matching_data
    },
    roundNumOrNc (num, prec = 2, suffix = null) {
      return this.$commonUtils.isNumeric(num) && typeof num === 'number' ? `${this.$commonUtils.roundNum(num, prec)}${typeof suffix === 'string' ? suffix : ''}` : 'NC'
    },
    numberToNegative (value) {
      if (isNaN(Number(value))) {
        return 'NC'
      }
      return -Number(value)
    },
    generateUrl (instructionStratId) {
      return { name: 'models', params: { dsp: this.$route.params.dsp, instructionStratId: instructionStratId } }
    },
    ratioValueTreatment (ratioValue) {
      return this.$issetAndNotEmpty(ratioValue) ? `${this.$commonUtils.ratioToPerc(ratioValue)} %` : 'NC'
    },
    isRedPushDate (pushDate) {
      let diff = this.$commonUtils.getDiffDate(pushDate)
      let oneDay = 1000 * 60 * 60 * 24
      let threeDay = oneDay * 3
      return this.status === 'live' && diff > threeDay
    },
    isAvgBidError (item) {
      return (this.isAvgBidTooHigh(item) || this.roundNumOrNc(item.average_bid) === '0')
    },
    isAvgBidTooHigh (item) {
      return (this.roundNumOrNc(item.max_CPM) !== 'NC' &&
        this.roundNumOrNc(item.average_bid) !== 'NC' &&
        (item.average_bid >= item.max_CPM * 0.9))
    },
    perPositiveTooLow (imps) {
      return (this.roundNumOrNc(imps) !== 'NC' && this.roundNumOrNc(imps, 3, '%') === '0%')
    },
    pixelIdToConversionFunnel (pixelId) {
      return { 1: pixelId }
    },
    /**
     * @param item {InstructionStrat}
     * @returns {boolean}
     */
    displayStepSelected (item) {
      const funnel = item.data_pixel_id
      const funnelHasDspPixel = Array.isArray(funnel) && funnel.length && funnel.some((i) => {
        return i.type === 'pixel' && i.origin === 'dsp'
      })
      const modelInfoHasStepSelected = this.$isNotNullOrUndefined(item.model_info) && this.$isNotNullOrUndefined(item.model_info.step_selected)
      return funnelHasDspPixel && modelInfoHasStepSelected
    },
    /**
     * @param item {InstructionStrat}
     * @returns {string}
     */
    valueKpi (item) {
      return this.displayStepSelected(item) ? item.model_info.step_selected : ''
    },
    /**
     * @param float {number}
     * @return {null|number|*}
     */
    displayBigFloat (float) {
      if (!this.$commonUtils.isNumeric(float)) {
        return null
      }
      if (Number.isInteger(float)) {
        return float
      }
      return this.$commonUtils.roundNum(float, 4)
    },
    isCustomAlgo (item) {
      return this.$isNotNullOrUndefined(item.AB_effective) && this.$isNotNullOrUndefined(item.AB_effective.A)
    },
    /**
     * @param frequencyCap {MaxFrequency|String}
     * @returns {string}
     */
    frequencyCapToString (frequencyCap) {
      if (typeof frequencyCap === 'string') {
        return frequencyCap
      } else if (this.$isNotNullOrUndefined(frequencyCap) && !this.$commonUtils.objectIsEmpty(frequencyCap)) {
        return `${frequencyCap.exposures} per ${frequencyCap.amount} ${frequencyCap.period}`
      }
      return 'NC'
    },
    /**
     * @param item {InstructionStrat}
     * @returns {ABParamsType}
     */
    getABParams (item) {
      const dsp = this.$route.params.dsp
      if (dsp === $DBM) {
        const effectifA = this.$isNotNullOrUndefined(item.AB_effective) && this.$isNotNullOrUndefined(item.AB_effective.A)
          ? item.AB_effective.A
          : null
        // if AB_effective.A is set and not null, AB_effective is returned (we are in custom algo)
        // otherwise, AB_params is returned
        if (effectifA != null) {
          return item.AB_effective
        }
      }
      // default
      return item.AB_params
    },
    /**
     * @param item {InstructionStrat}
     * @param key {keyof ABParamsType}
     * @returns {number|'NC'}
     */
    getABParamsProperty (item, key) {
      const abParams = this.getABParams(item)
      if (abParams) {
        return this.$isNotNullOrUndefined(abParams[key]) ? this.displayBigFloat(abParams[key]) : 'NC'
      }
      return 'NC'
    },
    /**
     * @param item {InstructionStrat}
     * @returns {number}
     */
    getOffset (item) {
      if (this.$route.params.dsp === $DBM) {
        let offset = item.offset_effective != null ? item.offset_effective : item?.objective?.offset
        return offset || 0
      }
      return item?.objective?.offset || 0
    },
    /**
     * @param item {InstructionStrat}
     * @returns {boolean}
     */
    displaySource (item) {
      return item?.p3_model_info || item?.p3_config?.use_forced_ratio
    },
    /**
     * @param item {InstructionStrat}
     * @returns {string}
     */
    getSource (item) {
      const NON_ATTRIBUTED = 'NA'
      if (!item.p3_config) {
        return NON_ATTRIBUTED
      }
      if (item.p3_config.use_surcouche_ratio) {
        return 'Surcouche'
      } else if (item.p3_config.use_forced_ratio) {
        return 'Internal'
      } else if (item.p3_config.optimize_p3_kpi || item.p3_config.respect_p3_light_constraint || item.p3_config.respect_p3_hard_constraint) {
        return 'AI'
      } else if (item.p3_config.use_legacy_3rd_party) {
        return 'Interface 5000'
      } else {
        return NON_ATTRIBUTED
      }
    },
    /**
     * @param item {InstructionStrat}
     * @returns {*}
     */
    isDisplayedOptiRatio (item) {
      return item.p3_model_info && item.p3_model_info.variables && item.p3_model_info.variables.includes('line_item_id')
    },
    onUpdatePagination (newValue) {
      this.$emit('update:pagination', newValue)
    },
    getExecModeColor (execMode) {
      switch (execMode) {
        case 'daily':
          return '#0747A6'
        case 'retry':
          return null
        case 'fast_retry':
          return '#37B47F'
        case 'intraday':
          return '#006644'
        default:
          return null
      }
    },
    /**
     * @param status {StratStatus}
     * @returns {string|null}
     */
    getStatusColor (status) {
      switch (status) {
        case 'live':
          return '#3dc73d'
        case 'error':
          return 'red'
        case 'to_push':
          return 'orange'
        case 'waiting':
          return '#b17903'
        case 'replaced':
          return '#9b9a9a'
        default:
          return null
      }
    },
    booleanToString (value, truly = 'Activated', falsy = 'Deactivated', nullValue = 'NC') {
      if (value === null) {
        return nullValue
      }
      return value ? truly : falsy
    },
    displayInfoABParams (item) {
      return this.isDbm && this.isCustomAlgo(item)
    },
    /**
     * @param item {InstructionStrat}
     * @returns {boolean}
     */
    displayRatioTooltip (item) {
      return !!(item && item.p3_model_info)
    },
    getKpiCPA (item) {
      if (this.isDbm && this.isCustomAlgo(item)) {
        return this.roundNumOrNc(item.AB_effective.A, 3)
      }
      return this.roundNumOrNc(item.objective.KPI_CPA, 3)
    },
    /**
     * @param baseWidth {number|string} can be a number or a string, who can have 'em' at the end
     * @returns {string}
     */
    removeWidthTooltip (baseWidth) {
      const sizeTooltipStratInfo = 2.2
      if (typeof baseWidth === 'number') {
        return `${baseWidth - sizeTooltipStratInfo}em`
      } else if (typeof baseWidth === 'string') {
        return `${parseFloat(baseWidth) - sizeTooltipStratInfo}em`
      }
      console.warn('removeWidthTooltip : baseWidth is not a number or a string')
      return ''
    },
    /**
     * @param isoDate {string}
     * @returns {string}
     */
    dateFormat (isoDate) {
      return this.$commonUtils.isNotNullOrUndefined(isoDate) ? this.$commonUtils.formatDateStratMode(isoDate) : 'NC'
    },
    /**
     * @param headerName {string} one of the property name of the headers data.
     * @returns {boolean}
     */
    isDisplayedHeader (headerName) {
      // switch on all the property name of the props headers
      switch (headerName) {
        case 'viz_and_max_bid_applied':
          return !this.isYoutube
        case 'funnel_info':
          return this.status !== 'error' && !this.isYoutube
        case 'additional_info':
          return this.isYoutube
        case 'error':
          return this.status === 'error'
        default:
          return true
      }
    },
    getTooltipOffset (item) {
      console.warn(item.objective?.offset)
      console.warn(item.objective)
      if (item.objective?.offset === undefined) {
        return 'NC'
      }
      return item.objective?.offset === null ? 0 : item.objective?.offset
    }
  },
  computed: {
    headersByDsp () {
      let dsp = this.$route.params.dsp
      let baseFilteredHeaders = this.headers.filter(header => this.isDisplayedHeader(header.name))
      let headers = [this.idHeaders[dsp], ...baseFilteredHeaders, this.modelHeader]
      console.log(headers)
      if (this.displayStatusInfo) {
        return [...headers, this.statusInfoHeader]
      }
      return headers
    },
    modelsActivated () {
      return [this.$THETRADEDESK, this.$MEDIAMATH, this.$APPNEXUS, this.$DBM, this.$BEESWAX, this.$KAYZEN].indexOf(this.$route.params.dsp) !== -1
    },
    totalItems () {
      if (this.pagination.rowsPerPage === -1) {
        return this.instructionsStrat.length
      } else if (!this.loading && this.instructionsStrat.length < this.pagination.rowsPerPage) {
        return this.displayedPageStop
      }
      return (this.pagination.rowsPerPage * (this.pagination.page + 1)) + 1
    },
    displayedPageStop () {
      if (this.pagination.rowsPerPage === -1) {
        return this.instructionsStrat.length
      }
      const startPage = this.pagination.rowsPerPage * (this.pagination.page - 1)
      return this.instructionsStrat.length < this.pagination.rowsPerPage
        ? startPage + this.instructionsStrat.length
        : startPage + this.pagination.rowsPerPage
    },
    isAppnexus () {
      return this.$route.params.dsp === this.$APPNEXUS
    },
    isYoutube () {
      return this.$route.params.dsp === this.$YOUTUBE
    },
    isTheTradeDesk () {
      return this.$route.params.dsp === this.$THETRADEDESK
    },
    isDbm () {
      return this.$route.params.dsp === this.$DBM
    },
    instructionsStratSorted () {
      const sortToCheck = ['push_date', 'insertion_date']
      const sortBy = this.pagination.sortBy.length ? this.pagination.sortBy[0] : false
      if (sortToCheck.includes(sortBy)) {
        const stratKey = sortBy
        const desc = this.pagination.sortDesc && this.pagination.sortDesc.length ? this.pagination.sortDesc[0] : false
        const instruStrat = _.cloneDeep(this.instructionsStrat)
        return instruStrat.sort((a, b) => {
          return desc ? this.$commonUtils.sortDate(a[stratKey], b[stratKey])
            : this.$commonUtils.sortDate(b[stratKey], a[stratKey])
        })
      }
      return this.instructionsStrat
    },
    labelMinViz () {
      if (!this.isTheTradeDesk) {
        return 'Min Viz'
      }
      return 'Min Banner Viz'
    }
  }
}
</script>

<style>
.status_live {
  border: solid 1px #3dc73d;
}

.status_to_push {
  border: solid 1px orange;
}

.status_error {
  border: solid 1px red;
}

.status_waiting {
  border: solid 1px #b17903;
}

span.header_strat_Funnel_Info {
  /* width: 10em; */
  padding-right: 2em;
}

.data-table-outcomes table.v-table thead tr {
  height: 5em;
}

.show-red-text {
  color: red;
  font-weight: bold;
}

.error-details {
  font-size: 10px;
  cursor: initial;
}

.v-menu-errors {
  background: white;
  padding: 1em;
  font-size: 15px;
}

.strat-model-button {
}

.strat-model-button:not(.v-btn--disabled) {
  border: 2px solid gray !important;
  color: gray !important;
  transition: 0.1s;
}

.strat-model-button:not(.v-btn--disabled):hover {
  transform: scale(1.1);
}

.copy-details {
  font-size: 14px;
}

.v-data-table-header th.text-left.header-instru-strat {
  font-size: 18px;
  font-weight: 300;
  color: black;
}

.td-dates-and-id .new-label-value {
  padding: 0.25em;
}

.info-tooltip-abparams {
  color: #3486D7;
}

.icon-info-tooltip {
  font-size: 20px;
  color: #3486D7;
  background: #DDEBF9;
  border-radius: 45px;
  cursor: pointer;
}

.info-text-id {
  text-align: start;
}
</style>
