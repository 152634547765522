<template>
  <v-row no-gutters>
    <OutcomesSearchBarSimple
      v-on:refresh="emitComplexSearch()"
      v-on:search="emitSearch"
      ref="outcomesSearchBarSimpleInComplex"
      :disabled-refresh="disabledSearch"
    >
    </OutcomesSearchBarSimple>

    <v-row no-gutters>

      <v-menu

        v-model="datepickerMenuStart"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="startDate"
            label="Start date"
            prepend-icon="event"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          @input="datepickerMenuStart = false"
          :allowed-dates="allowedsDatesStart"
          :max="maxDate"
          :show-current="endDate"
        >
        </v-date-picker>
      </v-menu>

      <v-spacer></v-spacer>

      <v-menu

        v-model="datepickerMenuEnd"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="endDate"
            label="End date"
            prepend-icon="event"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="endDate"
          @input="datepickerMenuEnd = false"
          :allowed-dates="allowedsDatesEnd"
          :max="maxDate"
          :show-current="startDate"

        >
        </v-date-picker>
      </v-menu>

      <v-spacer></v-spacer>

      <v-select
        :items="statusSelect"
        label="Select status"
        v-model="selectedSearch"
      >

      </v-select>

      <v-btn
        color="info"
        @click="emitComplexSearch()"
        :disabled="isSearchDisabled"
      >
        Search
      </v-btn>

    </v-row>
  </v-row>
</template>

<script>
import OutcomesSearchBarSimple from './OutcomesSearchBarSimple'
import { generateListInsertionDay, dateToDatePickerDate, datePickerToInsertionDay } from '../../../../../utils/DatePickerHelper/datePickerHelper'

export default {
  name: 'OutcomesSearchBarComplex.vue',
  components: {
    OutcomesSearchBarSimple
  },
  props: {
    disabledSearch: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      datepickerMenuStart: false,
      datepickerMenuEnd: false,
      startDate: null,
      endDate: null,
      maxDate: null,
      statusSelect: [
        { text: 'pushed once', value: ['live', 'replaced'] },
        { text: 'pushed once with Math Prod', value: 'pushed_once_with_math_prod' },
        { text: 'error', value: ['error'] },
        { text: 'all', value: 'all' }
      ],
      /**
       * @type {string[] | 'pushed_once_with_math_prod' | 'all' | null}
       */
      selectedSearch: null
    }
  },
  created () {},
  mounted () {
    this.setDefaultValue()
  },
  methods: {
    setDefaultValue () {
      this.selectedSearch = ['live', 'replaced']
      this.endDate = dateToDatePickerDate(new Date())
      this.maxDate = this.endDate
      this.startDate = dateToDatePickerDate(this.$commonUtils.substractDate(new Date(), 4))
      this.$nextTick(() => {
        this.emitComplexSearch()
      })
    },
    emitSearch (toSearch) {
      this.$emit('search', toSearch)
    },
    emitComplexSearch () {
      let search = {
        startDate: datePickerToInsertionDay(this.startDate),
        endDate: datePickerToInsertionDay(this.endDate)
      }

      if (this.selectedSearch) {
        if (this.selectedSearch === 'pushed_once_with_math_prod') {
          search.executionMode = ['daily', 'retry']
        } else if (this.selectedSearch === 'all') {
          search.status = ['live', 'to_push', 'replaced', 'error', 'waiting']
        } else {
          search.status = this.selectedSearch
        }
      }

      this.$emit('complexSearch', search)
    },
    launchSearch () {
      this.setDefaultValue()
      this.emitComplexSearch()
    },
    allowedsDatesStart (val) {
      if (this.endDate === null) {
        return true
      }
      return datePickerToInsertionDay(val) <= datePickerToInsertionDay(this.endDate)
    },
    allowedsDatesEnd (val) {
      if (this.startDate === null) {
        return true
      }
      return datePickerToInsertionDay(val) >= datePickerToInsertionDay(this.startDate)
    },
    resetSearch () {
      this.$refs['outcomesSearchBarSimpleInComplex'].search = ''
    }
  },
  computed: {
    isSearchDisabled () {
      return !this.$issetAndNotEmpty(this.startDate) || !this.$issetAndNotEmpty(this.endDate) || !this.$issetAndNotEmpty(this.selectedSearch) || this.disabledSearch
    }
  }
}
</script>

<style scoped>

</style>
