
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import _ from 'lodash'

@Component({
})
export default class NewLabelValue extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ required: true }) value!: string | number
  @Prop({ default: 'NC' }) defaultValue!: string
  @Prop({ default: false }) isDate!: boolean
  @Prop({ default: 'black' }) colorValue!: string
  @Prop({ default: 'black' }) colorLabel!: string
  @Prop({ default: false }) boldValue!: boolean
  @Prop({ default: true }) boldLabel!: boolean
  @Prop({ default: '7em' }) widthLabel!: string

  get labelValue () {
    let value = _.isNil(this.value) ? this.defaultValue : this.value

    if (typeof value === 'string') {
      value = this.$commonUtils.htmlEntities(value)
    }

    let label = this.label

    if (this.isDate && this.value) {
      value = this.$commonUtils.formatDateStratMode(String(this.value))
    }

    if (this.boldValue) {
      value = `<strong>${value}</strong>`
    }

    if (this.boldLabel) {
      label = `<strong>${this.label}</strong>`
    }

    return `<v-col style="color: ${this.colorLabel}; width: ${this.widthLabel}">${label}:</v-col>
            <v-col style="color: ${this.colorValue}">${value}</v-col>`
  }
}
