
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import OutcomesInfoTooltip
  from '@/components/TableComponents/Outcomes/OutcomesTable/FragmentsTable/OutcomesInfoTooltip.vue'
import { P3ModelInfoType } from '../../../../../../types/instruction_type'

@Component({
  components: {
    OutcomesInfoTooltip
  }
})
export default class RatioTooltip extends Vue {
  @Prop({ required: true }) modelInfo!: P3ModelInfoType

  getPivotKeys () {
    if (!this.modelInfo.variables) return ''
    return this.modelInfo.variables.join(', ')
  }
}
