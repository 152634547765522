import { isoDateToDate } from '../commonUtils'

/**
 * Test available in tests/test_instruStratProcessor.js
 */
class InstruStratProcessor {
  /**
   * Business logic => https://docs.google.com/presentation/d/1TpLL6slKOIvlfjL5zpM6Eu2Kaq4VaXPpSF0fJH6OER8/edit#slide=id.p
   * "on n’affiche pas une ligne group_key G, status=error et insertion_date D s’il existe une ligne
   *  avec meme group_key G, status=live et push_date=P telle que P>D"
   * @param stratsError {Array<InstructionStrat>}
   * @param stratsLive {Array<InstructionStrat>}
   * @param unicityKey {StringKeys<InstructionStrat> | NumberKeys<InstructionStrat>} the key to use to compare the strat
   * @returns {[]} the list of old instru strat
   */
  getOldStratError (stratsError, stratsLive, unicityKey) {
    let idListOldStrat = new Set()

    for (let i in stratsLive) {
      if (!stratsLive.hasOwnProperty(i)) {
        continue
      }

      let stratsErrorSameKey = stratsError.filter((item) => {
        return item.hasOwnProperty(unicityKey) && item[unicityKey] === stratsLive[i][unicityKey]
      })

      if (stratsErrorSameKey.length <= 0) {
        continue
      }

      for (let y in stratsErrorSameKey) {
        if (!stratsErrorSameKey.hasOwnProperty(y)) {
          continue
        }
        if (this.isPushDateRecent(stratsErrorSameKey[y], stratsLive[i])) {
          idListOldStrat.add(stratsErrorSameKey[y].id)
        }
      }
    }
    return [...idListOldStrat]
  }

  /**
   * return true if the push date of the stratLive is more recent than the insertion_date of the stratError
   * @param stratError {Object}
   * @param stratLive {Object}
   * @returns {boolean}
   */
  isPushDateRecent (stratError, stratLive) {
    let insertionDate = isoDateToDate(stratError.insertion_date)
    let pushDate = isoDateToDate(stratLive.push_date)

    return pushDate > insertionDate
  }

  /**
   * => https://trello.com/c/fQ7AHVPJ/355-surcouche-outcomes-bug-sur-error
   * For stratError with the same unicityKey, keep the most recent insertion date.
   * Return a list with the id of these instruction strat
   * @param stratErrorList {Array}
   * @param unicityKey {String}
   * @return {Array} The id list of non duplicate instru Strat
   */
  getNotDuplicateErrorList (stratErrorList, unicityKey) {
    let nonDuplicateList = {}
    for (let i in stratErrorList) {
      if (!stratErrorList.hasOwnProperty(i)) {
        continue
      }

      if (typeof nonDuplicateList[stratErrorList[i][unicityKey]] === 'undefined' ||
        this.isDateMoreRecent(stratErrorList[i], nonDuplicateList[stratErrorList[i][unicityKey]])) {
        nonDuplicateList[stratErrorList[i][unicityKey]] = stratErrorList[i]
      }
    }
    return Object.keys(nonDuplicateList).map((key) => nonDuplicateList[key].id)
  }

  /**
   * return true if stratA.insertion_date is more recent than stratB.insertion_date
   * @param stratA
   * @param stratB
   * @returns {boolean}
   */
  isDateMoreRecent (stratA, stratB) {
    let insertionDateA = isoDateToDate(stratA.insertion_date)
    let insertionDateB = isoDateToDate(stratB.insertion_date)
    return insertionDateA > insertionDateB
  }
}

export {
  InstruStratProcessor
}
